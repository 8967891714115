<template>
  <div id="app" class="mt-5">
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    
  <div id="app">
    <div class="w-25">
      <VueSelect
        v-model="selectedOption"
        :options="customers"
        labelColumn="fullName" 
        valueColumn="reference" 
        placeholder="Select an option"
      />
    </div>
  </div>


  <br />
   

  <DataTable
    :headers="headers"
    :data="sales"
    :summableColumns="[
      { field: 'totalPriceTTC', footerClass: 'text-primary' },
      { field: 'totalQuantity', footerClass: 'text-secondary' },
      { field: 'amountPaid', footerClass: 'text-success' },
    ]"
    :buttons="[
      { label: 'Edit', class: 'btn-primary', icon: 'bi bi-pencil', handler: editRow },
      { label: 'Delete', class: 'btn-danger', icon: 'bi bi-trash', handler: deleteRow }
    ]"
  />

    <!-- <sale-data-table></sale-data-table> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTable from "../components/cards/DataTable.vue";
import VueSelect from '../components/cards/VueSelect.vue';
// import SaleDataTable from "../components/cards/SaleDataTable.vue";

export default {
  name: "App",
  components: {
    DataTable,
    VueSelect,
  },
  data() {
    return {
      headers: [
        { label: "Référence", field: "reference" },
        { label: "Client", field: "customerFullName" },
        { label: "totalTTC", field: "totalPriceTTC" },
        { label: "Quantite", field: "totalQuantity" },
        { label: "Impaie", field: "amountPaid" },
        { label: "Creer par", field: "userName" },
        { label: "Statut", field: "validated" },
      ],
      
      actionButtons: [
        {
          label: 'Modifier',
          class: 'border border-secondary',
          handler: this.openEditor
        },
        {
          label: 'Supprimer',
          class: 'border border-secondary',
          handler: this.deleteRow
        },
        {
          label: 'Afficher',
          class: 'border border-secondary',
          handler: this.showDetails
        }
      ],
      selectedOption: '',
      options: [
        { label: 'aaaa 1', value: 'option1' },
        { label: 'bbbb 2', value: 'option2' },
        { label: 'ccc 3', value: 'option3' }
      ]
    };
  },
  methods:{
    openEditor(row) {
      this.$router.push({name:'sales-edit',params:{reference:'zzzzz'}})
    },
    deleteRow(row) {
      // Handle delete action
    },
    showDetails(row) {
      // Handle details action
    }
  },
  computed: {
    ...mapGetters("sale", {
      sales: "getAll",
      totalQuantity: "getSalesTotalQuantity",
      totalPriceTTC: "getSalesTotalPriceTTC",
      totalPaid: "getSalesTotalPaid",
      totalUnpaid: "getSalesTotalUnpaid",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
  },
  created() {
    this.$store.dispatch("customer/getAll");
    this.$store.dispatch("sale/fetchSales");
  },
};
</script>
