var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5",attrs:{"id":"app"}},[_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"w-25"},[_c('VueSelect',{attrs:{"options":_vm.customers,"labelColumn":"fullName","valueColumn":"reference","placeholder":"Select an option"},model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}})],1)]),_c('br'),_c('DataTable',{attrs:{"headers":_vm.headers,"data":_vm.sales,"summableColumns":[
    { field: 'totalPriceTTC', footerClass: 'text-primary' },
    { field: 'totalQuantity', footerClass: 'text-secondary' },
    { field: 'amountPaid', footerClass: 'text-success' },
  ],"buttons":[
    { label: 'Edit', class: 'btn-primary', icon: 'bi bi-pencil', handler: _vm.editRow },
    { label: 'Delete', class: 'btn-danger', icon: 'bi bi-trash', handler: _vm.deleteRow }
  ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }